import LogoBlue from 'assets/LogoBlue.svg'
import SidebarMenu from 'assets/icons/sidebarMenu.svg'
import { Button } from 'common/components/Button/Button'
import { NavLink } from 'common/components/Layout/NavLink'
import { pagesPathsEnum } from 'common/router/router.enum'
import { i18n } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PrimeIcons } from 'primereact/api'
import { Sidebar } from 'primereact/sidebar'
import { FC, useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useUserPublic } from 'services/auth/hooks/useUser'
import styled, { css } from 'styled-components'

interface IIndividualsNavbar {
  text: string
  redirectIfAuthenticated?: (urlYes: string, urlNo: string) => void
}

const IndividualsNavbar: FC<IIndividualsNavbar> = ({ text, redirectIfAuthenticated }) => {
  const router = useRouter()
  const { data: user, isLoading } = useUserPublic()
  const [isVisible, setIsVisible] = useState(false)
  const selectedMap = { en: 'US', ro: 'RO', bg: 'BG' }
  const [selected, setSelected] = useState(selectedMap[(router.locale as keyof typeof selectedMap) ?? 'en'])

  return (
    <>
      <Div type={router.pathname} className="hidden xl:flex px-2 py-1">
        <div className="nav-links flex gap-4 align-items-center">
          <Link href={pagesPathsEnum.root} onClick={() => setIsVisible(false)} aria-label="Seaplify | Go to homepage">
            <LogoBlue />
          </Link>
          <NavLink href={pagesPathsEnum.communityJobs} onClick={() => setIsVisible(false)}>
            Jobs
          </NavLink>
          <NavLink href={pagesPathsEnum.communityWiki} onClick={() => setIsVisible(false)}>
            Community
          </NavLink>
          <NavLink href={pagesPathsEnum.whatsApp} onClick={() => setIsVisible(false)}>
            WhatsApp
          </NavLink>
        </div>
        <div className="flex align-items-center gap-3">
          <Button
            variant="black"
            size="large"
            style={{ borderRadius: '32px', gap: '0', paddingLeft: '13px', fontSize: '13px', fontWeight: '600' }}
            onClick={() => router.push(router.pathname.includes('recruiters') ? '/' : '/recruiters')}
          >
            {text} <i className={PrimeIcons.ANGLE_RIGHT} />
          </Button>
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code) => {
              setSelected(code)
              const changeLanguage = (loc: any) => {
                const locale = loc.toLowerCase() !== 'us' ? loc.toLowerCase() : 'en'
                i18n && i18n.changeLanguage(locale)
                router.push(router.pathname, router.asPath, { locale })
              }

              changeLanguage(code)
            }}
            className="pb-0"
            countries={['RO', 'US', 'BG']}
            customLabels={{ US: 'EN', RO: 'RO', BG: 'BG' }}
          />
          {router.pathname.includes('recruiters') && (
            <Button
              size="large"
              onClick={() => redirectIfAuthenticated && redirectIfAuthenticated('/dashboard', '/login')}
              isLoading={isLoading}
              style={{ visibility: isLoading ? 'hidden' : 'visible' }}
            >
              {user ? 'Dashboard' : 'Login'}
            </Button>
          )}
        </div>
      </Div>

      <div className="xl:hidden flex justify-content-between align-items-center">
        <Link href={pagesPathsEnum.root} onClick={() => setIsVisible(false)} aria-label="Seaplify | Go to homepage">
          <LogoBlue />
        </Link>

        <Sidebar visible={isVisible} onHide={() => setIsVisible(false)} position="right">
          <div className="h-full">
            <Div className="nav-links flex flex-column justify-content-center gap-4 h-full">
              <Link
                href={pagesPathsEnum.root}
                onClick={() => setIsVisible(false)}
                className="absolute"
                style={{ top: '80px' }}
              >
                <LogoBlue />
              </Link>
              {router.pathname.includes('recruiters') && (
                <Button
                  size="large"
                  onClick={() => redirectIfAuthenticated && redirectIfAuthenticated('/dashboard', '/login')}
                  className="mb-5"
                >
                  {user ? 'Dashboard' : 'Login'}
                </Button>
              )}
              <NavLink href={pagesPathsEnum.communityJobs} onClick={() => setIsVisible(false)}>
                Jobs
              </NavLink>
              <NavLink href={pagesPathsEnum.communityWiki} onClick={() => setIsVisible(false)}>
                Community
              </NavLink>
              <NavLink href={pagesPathsEnum.whatsApp} onClick={() => setIsVisible(false)}>
                WhatsApp
              </NavLink>
              <div className="flex gap-3 align-items-center flex-column mt-5">
                <Button
                  variant="greyShadow"
                  size="medium"
                  style={{ borderRadius: '32px', gap: '0', paddingLeft: '13px', fontSize: '13px', fontWeight: '600' }}
                  onClick={() => router.push(router.pathname.includes('recruiters') ? '/' : '/recruiters')}
                >
                  {text} <i className={PrimeIcons.ANGLE_RIGHT} />
                </Button>
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => {
                    setSelected(code)
                    const changeLanguage = (loc: any) => {
                      const locale = loc.toLowerCase() !== 'us' ? loc.toLowerCase() : 'en'
                      i18n && i18n.changeLanguage(locale)
                      router.push(router.pathname, router.asPath, { locale })
                    }

                    changeLanguage(code)
                  }}
                  countries={['RO', 'US', 'BG']}
                  customLabels={{ US: 'EN', RO: 'RO', BG: 'BG' }}
                />
              </div>
            </Div>
          </div>
        </Sidebar>
        <Button
          onClick={() => setIsVisible(true)}
          style={{ backgroundColor: '#FFFCEB', padding: '8px' }}
          aria-label="Open sidebar menu"
        >
          <SidebarMenu />
        </Button>
      </div>
    </>
  )
}

const Div = styled.div<{ type?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.nav-links,
  .nav-links {
    a {
      text-transform: uppercase;

      &.active {
        padding-bottom: 5px;
        border-bottom: 3px solid ${({ theme }) => theme.colors.primary_100};
      }
    }
  }
  #rfs-btn {
    border: none !important;
  }

  ${({ type }) =>
    type &&
    type.includes('recruiters') &&
    css`
      [class*='ReactFlagsSelect-module_selectBtn__']:after {
        border-top: 5px solid ${({ theme }) => theme.colors.black};
        border-bottom: 0;
      }

      [class^='ReactFlagsSelect-module_selectBtn__'][aria-expanded='true']:after {
        border-bottom: 5px solid ${({ theme }) => theme.colors.black};
        border-top: 0;
      }

      [class^='ReactFlagsSelect-module_selectValue__'] {
        color: ${({ theme }) => theme.colors.black};
      }
    `};
`

export default IndividualsNavbar
